import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { urlTitle2 } from "./utillity";
import news from "../all__news.json";
import { newsShedule } from "./shared";
import produktnaBerza from "../produktnaBerza.json";
import { useGlobalLocation } from "./LocationContext";
const IdContext = createContext();

export const useIdContext = () => useContext(IdContext);

export const IdProvider = ({ children }) => {
  let currentDay1 = new Date();
  let allNews = produktnaBerza.concat(news).sort((a, b) => {
    return Date.parse(b.time2) - Date.parse(a.time2);
  });
  const publishedNews = allNews.filter((item, index) => {
    let currentDay2 = new Date(allNews[index].time2);
    return item.published && currentDay1.valueOf() >= currentDay2.valueOf();
  });
  const publishedNewsPromo = publishedNews.filter((item) => {
    return item.promoExcluded !== true;
  });
  const { pathPart } = useGlobalLocation();
  const { category, id } = useParams();
  let post = setArticlePost();
  let modifiedNews = [];
  let actualBoxNews = [];
  let topNews = [];
  let promoNews = [];
  let produktnaBerzaArr = [];
  let obicajiArr = [];
  publishedNewsPromo.forEach((item) => {
    if (newsShedule[1].home_topNews.includes(parseInt(item.news_id))) {
      //pined top news in shared file
      topNews.push(item);
    } else {
      if (item.modified !== undefined && !item.promoDuration) {
        let newsDate = new Date(item.modified);
        let timeDifference = Math.floor((currentDay1 - newsDate) / (1000 * 60 * 60 * 24));
        // if condition - how many days can passed for modified news to stay in first/second box
        if (timeDifference < 7) {
          promoNews.push(item);
        } else {
          //modifed news
          modifiedNews.push(item);
        }
      } else if (newsShedule[0].home.includes(parseInt(item.news_id))) {
        //actual news via news ID in shared file
        actualBoxNews.push(item);
      } else if (item.category === "Produktna berza") {
        let newsDate = new Date(item.time2);
        let timeDifference = Math.floor((currentDay1 - newsDate) / (1000 * 60 * 60 * 24));
        if (timeDifference < 3) {
          promoNews.push(item);
        } else {
          //produktna berza all news
          produktnaBerzaArr.push(item);
        }
      } else {
        //all other news
        if (item.promoDuration !== undefined) {
          let newsDate = new Date(item.modified || item.time2);
          let timeDifference = Math.floor((currentDay1 - newsDate) / (1000 * 60 * 60));
          if (timeDifference < item.promoDuration) {
            promoNews.push(item);
          }
        } else {
          promoNews.push(item);
        }
      }
    }
  });
  let filtered1 = [];
  publishedNewsPromo.forEach((item) => {
    if (urlTitle2(item.title) !== id) {
      filtered1.push(item);
    }
  });

  const filterCategory = filtered1
    .filter((item) => urlTitle2(item.category) === pathPart[2])
    .sort(sortByDate)
    .slice(0, 3);
  const page_view = filtered1
    .filter((item) => item.page_view > 0)
    .sort((a, b) => {
      return parseInt(b.page_view) - parseInt(a.page_view);
    })
    .slice(0, 3);

  function sortByDate(item1, item2) {
    const date1 = new Date(item1.modified || item1.time2);
    const date2 = new Date(item2.modified || item2.time2);
    return date2 - date1;
  }
  promoNews.sort(sortByDate);
  modifiedNews = modifiedNews.sort(sortByDate).slice(0, 4);
  filtered1 = filtered1.slice(0, 3);
  produktnaBerza.sort(sortByDate);

  publishedNews.forEach((element) => {
    if (element.category === "Običaji i tradicija" && !element.promoExcluded) {
      obicajiArr.push(element);
    }
  });

  const testIntext2 = [];
  //for produktnaberza array we take a second element because first element is template and its not published
  [produktnaBerza[1], ...promoNews, ...modifiedNews].forEach((item) => {
    let newsDate = new Date(item.modified || item.time2);
    let timeDifference = Math.floor((currentDay1 - newsDate) / (1000 * 60 * 60 * 24));
    if (urlTitle2(item.title) !== id && timeDifference < 7 && item.category !== "Manifestacije") {
      testIntext2.push(item);
    }
  });
  let articlesInText = getRandomElements([...testIntext2, ...actualBoxNews], 2);
  function getRandomElements(arr, count) {
    return arr
      .sort(() => 0.5 - Math.random()) // Nasumično permutuje niz
      .slice(0, count); // Izvlači prvih 'count' elemenata
  }

  function setArticlePost() {
    return publishedNews.find((post) => {
      return urlTitle2(post.title) === id;
    });
  }
  return <IdContext.Provider value={{ obicajiArr, articlesInText, filtered1, filterCategory, page_view, category, id, post, publishedNews, promoNews, actualBoxNews, topNews, modifiedNews, produktnaBerza }}>{children}</IdContext.Provider>;
};
