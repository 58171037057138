import React from "react";
import ArticalBox from "../ArticalBox/ArticalBox";
import "./NewsBox.scss";

export default function NewsBox(props) {
  return (
    <div className={props.val[5]}>
      {props.val[0].slice(0, props.val[2]).map((news, index) => {
        return <ArticalBox key={index} n={news} classes={props.val[4]} />;
      })}
    </div>
  );
}
