import React from "react";
import { useState } from "react";
import Buttons from "../../UI/Button/Buttons";
import "./NoMatch.scss";
import data from "../../all__news.json";
import ArticalBox2 from "../../components/ArticalBox/ArticalBox";

export default function NoMatch() {
  const [categoryNews, setCategoryNews] = useState();
  const [allNews, setAllNews] = useState(() => {
    const publishedNews = [];
    data.forEach((x) => {
      if (x.published) {
        publishedNews.push(x);
      }
    });
    return publishedNews;
  });
  console.log("AllNews", allNews);
  const changePage = () => {
    console.log("Click");
    // this.props.history.push("/");
  };
  const setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper mainBox";
    } else {
      return "boxWrapper mainBox";
    }
  };
  const setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox2 key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  return (
    <div className="noMatch">
      <div className="noMatch-top">
        <img
          src={"/img/noMatch/noMatch.png"}
          alt="Greška 404: stranica nije pronađena. Vratite se na početnu stranicu."
        />
        <div>
          <span>UUUPS!</span>
          <h2>Greška 404: Stranica nije pronađena</h2>
          <p>
            Nešto se dogodilo! Trenutno ne možemo da pronađemo stranicu koju
            trazite ili stranica ne postoji. <br /> Molimo vas da osvežite
            stranicu ili pokušate da odete na neku drugu.
          </p>
          <Buttons children="Nazad na početnu" clicked={changePage} />
        </div>
      </div>
      <h1>Agroweb.rs - Najnovije vesti</h1>
      <section className="home__box__11">
        {setNewsBoxes([1, 7], allNews, "home__box__2-layout", setClass())}
      </section>
    </div>
  );
}
