import React, { createContext, useContext } from "react";

// Kreirajte kontekst
const FunctionContext = createContext();

// Kreirajte komponentu koja će pružiti kontekst
export function FunctionProvider({ children }) {
  const sharedFunction = (x) => {
    // Ovde implementirajte svoju funkciju
    console.log("Ovo je deljena funkcija.", x);
  };

  return (
    <FunctionContext.Provider value={sharedFunction}>
      {children}
    </FunctionContext.Provider>
  );
}

// Koristite custom hook za pristup kontekstu
export function useSharedFunction() {
  return useContext(FunctionContext);
}
