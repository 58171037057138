import React, { useState, Suspense, lazy, useLayoutEffect } from "react";
import "./Bars.scss";
import Backdrop from "./Backdrop/Backdrop";
// import Logo from "../../img/agroweb-logo.png";
import { FunctionProvider } from "../Helpers/Help";
import { useGlobalLocation } from "../../shared/LocationContext";
const TopBar = lazy(() => import("./TopBar/TopBar"));
const NavBar = lazy(() => import("./NavBar/NavBar"));
const ToggleButton = lazy(() => import("./SideDrawer/ToggleButton"));
const SideDrawer = lazy(() => import("./SideDrawer/SideDrawer"));
// const PostImage = lazy(() => import("../../containers/SinglePost/img/PostImage"));

export default function Bars() {
  const [sideDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [barName, setBarName] = useState("bars");
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [admin, setAdmin] = useState(false);
  const { pathPart } = useGlobalLocation();

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    let barName2 = "bars small";
    if (prevScrollPos >= currentScrollPos || currentScrollPos < 350) {
      barName2 = "bars";
    }
    setBarName(barName2);
    setPrevScrollPos(currentScrollPos);
  };
  const drawerToggleClickHandler = () => {
    setSiteDrawerOpen(!sideDrawerOpen);
  };
  const removeBackdrop = () => {
    setSiteDrawerOpen(false);
  };
  const id2 = () => {
    const map = {
      subvencije: "Subvencije",
      "produktna-berza": "Produktna berza",
    };
    return map[pathPart[2]] || null;
  };
  return (
    <header className={`${barName} ${id2()}`}>
      <div className="bars__links">
        <Backdrop clicked={removeBackdrop} show={sideDrawerOpen} />
        <Suspense fallback={<div></div>}>
          <FunctionProvider>
            <TopBar />
          </FunctionProvider>
          <NavBar admin={admin} />
          <ToggleButton clicked={drawerToggleClickHandler} />
          <SideDrawer show={sideDrawerOpen} clicked={drawerToggleClickHandler} />
        </Suspense>
      </div>
    </header>
  );
}
